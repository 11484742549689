<template>
  <!-- 配置区域 -->
  <div>
    <el-card>
      <div style="float:right">
        <el-button type="primary" style="margin:-20px 20px 10px 10px" @click="editCompanyButton">保存</el-button>
      </div>
    </el-card>
    <el-form :model="addFrom" layout="inline" :inline="true">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="薪资" width="120px" prop="wages">
            <el-input-number width="20px" v-model="addFrom.wages" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="薪制" width="120px" prop="salarySystem">
            <el-input-number width="20px" v-model="addFrom.salarySystem" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="奖金" width="120px" prop="bonusSubsidy">
            <el-input-number width="20px" v-model="addFrom.bonusSubsidy" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="工作日" width="120px" prop="workDay">
            <el-input-number width="20px" v-model="addFrom.workDay" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="上班时间" width="120px" prop="workTime">
            <el-input width="20px" v-model="addFrom.workTime" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="休息时间" width="120px" prop="restTime">
            <el-input width="20px" v-model="addFrom.restTime" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="电话补贴" width="120px" prop="phoneSubsidy">
            <el-input-number width="20px" v-model="addFrom.phoneSubsidy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="加班补贴" width="120px" prop="workSubsidy">
            <el-input-number width="20px" v-model="addFrom.workSubsidy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="餐补" width="120px" prop="mealTonic">
            <el-input-number width="20px" v-model="addFrom.mealTonic" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="项目补贴" width="120px" prop="projectSubsidy">
            <el-input-number width="20px" v-model="addFrom.projectSubsidy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="电脑补贴" width="120px" prop="computerSubsidy">
            <el-input-number width="20px" v-model="addFrom.computerSubsidy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="打车补贴" width="120px" prop="carSubsidy">
            <el-input-number width="20px" v-model="addFrom.carSubsidy" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="出差补贴" width="120px" prop="evectionSubsidy">
            <el-input-number width="20px" v-model="addFrom.evectionSubsidy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="住宿补贴" width="120px" prop="staySubsidy">
            <el-input-number width="20px" v-model="addFrom.staySubsidy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="交通补贴" width="120px" prop="trafficSubsidy">
            <el-input-number width="20px" v-model="addFrom.trafficSubsidy" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="绩效" width="120px" prop="perfMoney">
            <el-input-number width="20px" v-model="addFrom.perfMoney" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="绩效备注" width="120px" prop="perfRemark">
            <el-input width="20px" v-model="addFrom.perfRemark" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="其他福利" width="120px" prop="otherWelfare">
            <el-input width="20px" v-model="addFrom.otherWelfare" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="备注" width="120px" prop="remark">
            <el-input type="textarea" autosize v-model="addFrom.remark" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {
  addcompanytreatmentApi,
  editcompanytreatmentApi,
  selectcompanytreatmentByIdApi
} from "@/api/my/company/companytreatment";
export default {
  data() {
    return {
      addFrom: {}
    };
  },
  created(){
    this.selectcompanytreatmentButton();
  }
  ,
  methods: {
    editCompanyButton() {
      if (this.addFrom.id == null) {
        addcompanytreatmentApi(this.addFrom).then(res => {
          this.selectcompanytreatmentButton()
        });
      } else {
        editcompanytreatmentApi(this.addFrom).then(res => {
          this.selectcompanytreatmentButton()
        });
      }
    },
    //获取的结果
    selectcompanytreatmentButton() {
      selectcompanytreatmentByIdApi().then(res => {
        this.addFrom = res.result;
      });
    }
  }
};
</script>
<style scoped>
</style>