import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunCompanyTreatment'


const listcompanytreatmentApi =(params)=>getAction(baseURL+"/list",params);

const addcompanytreatmentApi=(params)=>postAction(baseURL+"/add",params);

const editcompanytreatmentApi=(params)=>putAction(baseURL+"/edit",params);

const deletecompanytreatmentByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectcompanytreatmentByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

export{
    selectcompanytreatmentByIdApi,
    deletecompanytreatmentByIdApi,
    editcompanytreatmentApi,
    addcompanytreatmentApi,
    listcompanytreatmentApi
}
